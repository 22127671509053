import React, {useEffect, useState, useRef} from "react";
import styled, {createGlobalStyle} from "styled-components";
import {
  Button,
  Input,
  Space,
  Table,
  Tag,
  Popconfirm,
  Modal,
  Form,
  Switch,
  message,
  Spin,
  Select,
  Checkbox,
  DatePicker,
  Tooltip,
  InputNumber,
  Col,
  Row,
  Tree,
} from "antd";
import API from "../../Store/Api/ApiService";
import {useNavigate} from "react-router-dom";
import {
  ArrowLeftOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
// import editicon from "../Assets/Images/edit.png";
// import deleteicion from "../Assets/Images/delete.png";
import axios from "axios";
import Group from "../Accounts/Group";
import moment from "moment";
const {Option} = Select;
const {OptGroup} = Select;
const {TreeNode} = Tree;

const LedgerMapping = () => {
  const [UserList, setUserList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [Print, setPrint] = useState([]);
  const [Ledger, setLedger] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [ChildLedger, setChildLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [ledgerId, setledgerId] = useState("");
  const [toDate, setToDate] = useState(null);
  const api = new API();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getAllUser = () => {
    setTableLoading(true);
    api
      .getUser()
      .then((res) => {
        console.log("ledger res", res);
        if (res?.status === 200) {
          setUserList(res?.data);
          setTableLoading(false);
        } else {
          setUserList([]);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        setUserList([]);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const User_List = [];
  UserList?.map((item) => {
    User_List.push({
      value: item?.user_id,
      label: item?.user_name,
    });
  });

  //Add Ledger Mapping
  const onFinishLedgerMapping = (values) => {
    setLoading1(true);
    const user_id = values["user_id"];
    const accounts_group_id = values["accounts_group_id"];
  
    const ledger = checkedKeys; 
  
    let addNew = {
      ledger,
      user_id,
    };
  
    if (values) {
      api
        .addledgermapping(addNew)
        .then((res) => {
          if (res?.data?.success === true) {
            setLoading1(false);
            message.success("Saved successfully");
          } else if (res?.data?.success === false) {
            message.warning(res?.data?.message);
            setLoading1(false);
          } else {
            message.error("Something went wrong!. Please try again!");
            setLoading1(false);
          }
        })
        .catch((err) => {
          setLoading1(false);
        });
    } else {
      message.error("Something went wrong!. Please try again!123");
      setLoading1(false);
    }
  };
  

  const handleUserChange = (value) => {
    console.log("value", value)
    setLoading(true);
    api
      .getledgermapping(value)
      .then((res) => {
        setTreeData(res?.data?.ledger);
        setCheckedKeys(res?.data?.userLedger);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convertDataToTree = (data) => {
    return data?.map((item) => {
      const newItem = {
        title: item?.group_name,
        key: item?.accounts_group_id,
      };
      if (item?.children && item?.children?.length > 0) {
        newItem.children = convertDataToTree(item?.children);
      }
  
      return newItem;
    });
  };
  
  const treeData1 = convertDataToTree(treeData);
  console.log("treeData1", treeData1);

const onCheck = (checkedKeysValue) => {
    console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue);
  };

  return (
    <React.Fragment>
      <DistrictSection>
        <div className="page_back_align">
          <p onClick={() => navigate(-1)} className="go_back">
            <ArrowLeftOutlined /> &nbsp; Ledger Mapping
          </p>
        </div>
        <div className="Top_Section">
          <Form form={form} layout="vertical" onFinish={onFinishLedgerMapping}>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item label="User" name="user_id">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={User_List}
                    onChange={handleUserChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{width: "100%"}}>
              {loading === false ? (
                <>
                  <Tree
                    checkable
                    onExpand={(expandedKeys) => {
                      console.log("Expanded keys:", expandedKeys);
                    }}
                    onCheck={onCheck}
                    treeData={treeData1}
                    checkedKeys={checkedKeys}
                  />
                
                </>
              ) : (
                <Spin />
              )}
            </div>
            <br />
            <Button
              type="primary"
              htmlType="submit"
              style={{position: "relative", left: "50%"}}
              loading={loading1}
            >
              save
            </Button>
          </Form>
        </div>
      </DistrictSection>
    </React.Fragment>
  );
};

export default LedgerMapping;

const DistrictSection = styled.section`
  width: 100%;

  position: relative;
  .go_back {
    font-family: "q_bold";
    cursor: pointer;
  }

  .switch_btn .ant-row.ant-form-item-row.css-dev-only-do-not-override-sk7ap8 {
    flex-wrap: wrap !important;
  }
  .switch_btn .ant-row {
    display: flex !important;
    flex-direction: row !important;
    flex-flow: nowrap !important;
    align-items: center;
  }

  .Form_Align {
    display: flex;
    flex-direction: row;
  }
`;

const FooterAlign = styled.div`
  width: 100%;
  .ant-table-summary {
    width: 100%;
  }

  .ant-table-summary-row {
    width: 100%;
  }

  .ant-table-summary-cell {
    width: 33.33%;
    text-align: center;
  }
`;
