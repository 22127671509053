import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {
  Button,
  Input, 
  Table,
  Tag,
  Popconfirm,
  Modal,
  Form,
  message,
  Select,
} from "antd";
import API from "../../Store/Api/ApiService";
import {useNavigate} from "react-router-dom";
import {
  ArrowLeftOutlined,
  PlusCircleOutlined, 
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const {Option} = Select;

const Group = () => {
  const [Group, setGroup] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [value, setValue] = useState("");
  const [filterdata, setFiterData] = useState([]);
  const [filterdata1, setFiterData1] = useState([]);
  const api = new API();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const navigate = useNavigate();

  //for access
  const [accessOptions, setAccessOptions] = useState([]);

  //to constrol the user  access
  const getAccess = (url) => {
    api
      .getaccesssettingpath(url)
      .then((res) => {
        if (res?.status == 200) {
          setAccessOptions(res?.data?.options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllGroup = () => {
    setTableLoading(true);

    api
      .getgroup()
      .then((res) => {
        if (res?.data?.success === true) {
          setGroup(res?.data?.data);
          setTableLoading(false);
        } else {
          setGroup([]);
          setTableLoading(false);
        }
      })
      .catch((err) => {
        setGroup([]);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getAllGroup();
    const currentPath = window.location.pathname;
    getAccess(currentPath);
  }, []);

  const data = [];

  Group?.map((item, i) => {
    data.push({
      key: i,
      accounts_group_id: i + 1,
      group_name: item?.group_name ? item?.group_name : "",
      parent_name: item?.parent_name ? item?.parent_name : "",
      status: item?.status,
      action: item?.accounts_group_id,
    });
  });

  const accounts_group_id = Group?.map((item) => item?.accounts_group_id);

  //Group List
  const Group_List = [];
  Group?.map((item) => {
    Group_List.push({
      value: item?.accounts_group_id,
      label: item?.group_name,
    });
  });

  //Delete Group
  const deleteGroup = (action) => {
    setLoading3(true);
    api.deletegroup(action).then((res) => {
      if (res?.data?.success === true) {
        setLoading3(false);
        message.success("Deleted successfully");
        getAllGroup();
      } else if (res?.data?.success === false) {
        message.warning(res?.data?.message);
        setLoading3(false);
      } else {
        message.error("Something went wrong!. Please try again!");
        setLoading3(false);
      }
    });
  };

  //Add New District
  const addnew_Group = () => {
    setIsModalOpen1(true);
  };

  const onFinishRole = (values) => {
    setLoading2(true);
    const group_name = values["group_name"];
    const parent_id = values["parent_id"];
    const status = values["status"];
    let addNew = {
      group_name,
      parent_id,
      status,
    };

    if (
      (group_name !== "" || group_name !== undefined) &&
      (parent_id !== "" || parent_id !== undefined) &&
      (status !== "" || status !== undefined)
    ) {
      api
        .addgroup(addNew)
        .then((res) => {
          if (res?.data?.success === true) {
            setLoading2(false);
            form.resetFields();
            setIsModalOpen1(false);
            message.success("Added successfully");
            getAllGroup();
          } else if (res.data.success === false) {
            message.warning(res.data.msg);
            setLoading2(false);
          } else {
            message.error("Something went wrong!. Please try again!");
            setLoading2(false);
          }
        })
        .catch((err) => {
          setLoading2(false);
        });
    } else {
      message.error("Something went wrong!. Please try again!");
      setLoading2(false);
    }
  };

  //Pending View & Update
  const viewRole = (action) => {
    setIsModalOpen(true);
    setLoading(true);

    api
      .viewgroup(action)
      .then((res) => {
        if (res.data.success === true) {
          form1.setFieldsValue({
            ...res?.data?.data[0],
            parent_id: res?.data?.data[0]?.parent_id == "0" ? "" : res?.data?.data[0]?.parent_id,
            status: parseInt(res?.data?.data[0]?.status),
            e_id: res?.data?.data[0]?.accounts_group_id || "",
          });
          setFiterData1(res?.data?.data[0]);
          setLoading(false);
        } else {
          setIsModalOpen(false);
          message.error("Something went wrong!. Please try again!");
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const updateDemurrageApi = (values) => {    
    const group_name = values["group_name"];
    const parent_id = values["parent_id"];
    const status = values["status"];
    const e_id = values["e_id"];

    let updateApi = {
      ...filterdata1,
      group_name: group_name,
      parent_id: parent_id,
      e_id: e_id,
      status: status,
    };

    api
      .editgroup(e_id, updateApi)
      .then((res) => {
        if (res?.data?.success === true) {
          setIsModalOpen(false);
          setLoading1(false);
          message.success("Updated successfully");
          getAllGroup();
        } else if (res?.data?.success === false) {
          message.warning(res?.data?.msg);
          setLoading1(false);
        } else {
          message.error("Something went wrong!. Please try again!");
          setLoading1(false);
        }
      })
      .catch((err) => {});
  };

  //Popup Model
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "S No",
      dataIndex: "accounts_group_id",
      key: "accounts_group_id",
      width: "2%",
    },
    {
      title: "Group name",
      dataIndex: "group_name",
      key: "group_name",
      width: "12%",
    },
    {
      title: "Parent name",
      dataIndex: "parent_name",
      key: "parent_name",
      width: "12%",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "4%",
      render: (_, {status}) => {
        let color;
        let text;
        if (status == 1) {
          color = "green";
          text = "Active";
        } else {
          color = "orange";
          text = "Inactive";
        }
        return (
          <Tag color={color} key={status}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action,name",
      key: "action,name",
      fixed: "right",
      width: "3%",
      render: (action, name) => (
        <div style={{display: "flex"}}>
          {accessOptions.includes("update") ? (
            <p
              className="action_btn edit"
              onClick={() => viewRole(name?.action)}
            >
              <EditOutlined />
            </p>
          ) : null}

          {accessOptions.includes("delete") ? (
            <Popconfirm
              title=" Are you Sure to delete?"
              onConfirm={() => deleteGroup(name?.action)}
              okButtonProps={{
                loading: loading3,
              }}
            >
              <p className="action_btn delete">
                <DeleteOutlined />
              </p>
            </Popconfirm>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DistrictSection>
        <Modal
          title="Add "
          open={isModalOpen1}
          onOk={handleOk1}
          onCancel={handleCancel1}
          okText="Create"
          width={400}
          footer={null}
        >
          <Form
            name="Demurrage_add"
            layout="vertical"
            onFinish={onFinishRole}
            form={form}
          >
            <Form.Item
              label="Group Name"
              name="group_name"
              rules={[
                {
                  required: true,
                  message: "Group Name is Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
            label="Under" 
            name="parent_id"
            >
              <Select
                showSearch
                allowClear
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label.toLowerCase() ?? "")
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={accounts_group_id}
                options={Group_List}
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"              
              rules={[
                {
                  required: true,
                  message: "Status is required!",
                },
              ]}
            >
              <Select 
              // defaultValue={"2"}
              placeholder="--Select--"
              >
                <Option 
                // value={"2"}
                >--Select--</Option>
                <Option value={1}>Active</Option>
                <Option value={0}>In Active</Option>
              </Select>
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={loading2}>
              Save
            </Button>
          </Form>
        </Modal>
        <Modal
          title="Edit"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Update"
          width={400}
          footer={null}
        >
          <Form
            name="Demurrage_edit"
            layout="vertical"
            onFinish={updateDemurrageApi}
            form={form1}
          >
            <Form.Item label="" name="e_id" type="hidden" style={{height: 0}}>
              <Input name="e_id" type="hidden" />
            </Form.Item>
            <Form.Item
              label="Group Name"
              name="group_name"
              rules={[
                {
                  required: true,
                  message: "This field is Required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Under" name="parent_id">
              <Select
                showSearch
                allowClear
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label.toLowerCase() ?? "")
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={accounts_group_id}
                options={Group_List}
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Status is required!",
                },
              ]}
            >
              <Select>
                <Option value={1}>Active</Option>
                <Option value={0}>In Active</Option>
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={loading1}>
              Save
            </Button>
          </Form>
        </Modal>

        <div className="page_back_align">
          <p onClick={() => navigate(-1)} className="go_back">
            <ArrowLeftOutlined /> &nbsp; Group
          </p>
          {accessOptions.includes("add") ? (
            <Button type="primary" size="small" onClick={() => addnew_Group()}>
              <PlusCircleOutlined />
              New
            </Button>
          ) : null}
        </div>
        {accessOptions.includes("list") ? (
          <div style={{float: "left", marginBottom: "20px"}}>
            <Input.Search
              placeholder="Input search text"
              value={value}
              onChange={(e) => {
                const currValue = e.target.value;
                setValue(currValue);
                const filteredData = data?.filter(
                  (entry) =>
                    entry.group_name
                      .toLowerCase()
                      .includes(currValue.toLowerCase()) ||
                    entry.parent_name
                      .toLowerCase()
                      .includes(currValue.toLowerCase())
                );
                setFiterData(filteredData);
              }}
              allowClear
              style={{width: 200, float: "right"}}
            />
          </div>
        ) : null}
        {accessOptions.includes("list") ? (
          <Table
            columns={columns}
            dataSource={value ? filterdata : data}
            bordered
            loading={tableLoading}
            responsive={true}
            size="small"
            scroll={{
              x: 800,
            }}
          />
        ) : null}
      </DistrictSection>
    </React.Fragment>
  );
};

export default Group;

const DistrictSection = styled.section`
  width: 100%;
  display: inline-block;
  position: relative;
  .go_back {
    font-family: "q_bold";
    cursor: pointer;
  }

  .switch_btn .ant-row.ant-form-item-row.css-dev-only-do-not-override-sk7ap8 {
    flex-wrap: wrap !important;
  }
  .switch_btn .ant-row {
    display: flex !important;
    flex-direction: row !important;
    flex-flow: nowrap !important;
    align-items: center;
  }
`;
