import axios from "axios";
// import {generateToken, setTokenInLocalStorage} from '../token'

// const token = generateToken();
//   setTokenInLocalStorage(token);

const BASE_URL = process.env.REACT_APP_API;
// const BASE_URL_NEW = process.env.REACT_APP_API;
// const Base_URL1 = process.env.REACT_APP_API_New;
const LOGIN_URL = process.env.LOGIN_URL;
const AUTH_TOKEN = process.env.AUTH_TOKEN;
const user =  JSON.parse(localStorage.getItem("persist:root"))?.admin ?? "";
const Time = localStorage.getItem("T")
// const isAuthenticated = user && JSON.parse(user).isAuthenticated;
// console.log(JSON.parse(Time))
const token = user != "" ? JSON.parse(user)?.user?.authorization : "";
// const isToken = `Bla|MkxNckpaU0tiUmZ5ajZvSUp2Mzh1QT09|1687441989164`;
export const isToken = `Bla|${token}|${Time}`;
// console.log(isToken)
// console.log("baseurl", BASE_URL)
const company = process.env.REACT_APP_COMPANY_NAME;

const localAPIToken = "Bla|MkxNckpaU0tiUmZ5ajZvSUp2Mzh1QT09|1687441989164"

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: isToken,
    "Content-Type": 'application/json, text/plain, */*',
    Accept: 'application/json',
   
  },
});
// export const userRequest = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     authorization: `Bla|MkxNckpaU0tiUmZ5ajZvSUp2Mzh1QT09|1687441989164`,
//     "Content-Type": 'application/json, text/plain, */*',
//     Accept: 'application/json',
   
//   },
// });

export const userRequest1 = axios.create({
  baseURL: BASE_URL,
  headers: {
    authorization: localAPIToken,
    "Content-Type": 'application/json, text/plain, */*',
    Accept: 'application/json',
   
  },
});

export const getRequest = axios.create({
  baseURL: BASE_URL,
 
  headers: {
    Authorization: isToken,
    "Content-Type": 'application/json, text/plain, */*',
    Accept: 'application/json',
  },

});





