import React from 'react'
import './Assets/Css/style.css'
import Dashboard from './Pages/Dashboard';
import { BrowserRouter } from 'react-router-dom'


const App = () => {
  
  return (
    <React.Fragment>
      <Dashboard />
    </React.Fragment>
  )
}

export default App;